<template>
  <!-- width and height are optional -->
  <div class="absolute -bottom-4 -left-20">
    <lottie
      :width="150"
      :height="200"
      :options="lottieOptions"
      v-on:animCreated="handleAnimation"
    />
  </div>
</template>

<script>
import lottie from 'vue-lottie/src/lottie.vue';
import * as thumbsUpAnimation from '@/assets/igbce/lottie/thumbs-up.json';
import * as laughAnimation from '@/assets/igbce/lottie/laugh.json';
import * as sadAnimation from '@/assets/igbce/lottie/sad.json';
import * as smileAnimation from '@/assets/igbce/lottie/smile.json';
import * as applausAnimation from '@/assets/igbce/lottie/applause.json';
import * as rocketAnimation from '@/assets/igbce/lottie/rocket.json';
import * as heartAnimation from '@/assets/igbce/lottie/heart.json';

export default {
  components: {
    lottie,
  },

  props: {
    reactionName: {
      type: String,
      required: true,
      default: 'applaus',
    },
  },
  data() {
    return {
      anim: null, // for saving the reference to the animation
    };
  },
  computed: {
    lottieOptions() {
      let animationData = null;

      switch (this.reactionName) {
        case 'thumbs-up': {
          animationData = thumbsUpAnimation;
          break;
        }
        case 'funny': {
          animationData = laughAnimation;
          break;
        }
        case 'sad': {
          animationData = sadAnimation;
          break;
        }
        case 'smile': {
          animationData = smileAnimation;
          break;
        }
        case 'claps': {
          animationData = applausAnimation;
          break;
        }
        case 'rocket': {
          animationData = rocketAnimation;
          break;
        }
        case 'love': {
          animationData = heartAnimation;
          break;
        }

        default: {
          animationData = rocketAnimation;
          break;
        }
      }

      return {
        animationData: animationData.default,
        loop: true,
        renderer: 'svg',
        autoplay: true,
      };
    },
  },
  methods: {
    handleAnimation: function (anim) {
      this.anim = anim;
    },
  },
};
</script>
