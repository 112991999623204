



































import Vue from 'vue';
import ReactionVector from '../Viewer/ReactionVector.vue';
import LottieWrapper from '@/components/Viewer/LottieWrapper.vue';
export default Vue.extend({
  name: 'ReactionBarItem',
  components: { ReactionVector, LottieWrapper },
  props: [
    'color',
    'fillLevel',
    'label',
    'imageSrc',
    'name',
    'value',
    'invertColors',
    'showLabel',
    'renderAnimation',
    'animationName',
  ],
  computed: {
    computedImageSrc(): any {
      return require('@/assets/reaction-icons/' + this.imageSrc);
    },
    computedReactionColor() {
      return this.invertColors ? '#fff' : '#4B5362';
    },
  },
});
